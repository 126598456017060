var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-card',[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":6}},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("关键字搜索：")]),_c('el-input',{staticClass:"input searchInput",staticStyle:{"width":"250px"},attrs:{"size":"mini","suffix-icon":"el-icon-search","placeholder":"请输入车辆三检项","maxlength":"15","clearable":""},on:{"input":_vm.queryList},model:{value:(_vm.ajaxParam.checkItemName),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "checkItemName", $$v)},expression:"ajaxParam.checkItemName"}})],1),_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"pl-1"},[_c('span',{staticClass:"text-primary text-pad-right"},[_vm._v("公司：")]),_c('GlobalCompanySelect',{staticStyle:{"width":"250px"},attrs:{"size":"mini"},on:{"change":_vm.queryList},model:{value:(_vm.ajaxParam.companyId),callback:function ($$v) {_vm.$set(_vm.ajaxParam, "companyId", $$v)},expression:"ajaxParam.companyId"}})],1)])],1)],1),_c('el-card',{staticStyle:{"margin-top":"20px"}},[_c('el-row',[_c('vxe-toolbar',{scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-row',{attrs:{"type":"flex","justify":"between"}},[_c('el-col',[(_vm.$hasBtn('sys:permissions:config:timed_task:add'))?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){_vm.recordRow=null;_vm.showAddOrEdit=true}}},[_vm._v("添加")]):_vm._e(),(_vm.$hasBtn('sys:permissions:config:timed_task:batch:delete'))?_c('el-button',{attrs:{"type":"danger","size":"mini","disabled":_vm.batchRecordRows.length===0?true:false},on:{"click":_vm.batchDel}},[_vm._v("批量删除")]):_vm._e()],1),_c('el-col',{attrs:{"span":1}},[_c('vxe-button',{on:{"click":function (){
                                    _vm.ajaxParam.currentPage = 1;
                                    _vm.ajaxParam.pageSize = 20;
                                    _vm.ajaxParam.jobName = null;
                                    _vm.ajaxParam.jobStatus = null;
                                    _vm.queryList();
                                }}},[_vm._v("刷新")])],1)],1)]},proxy:true}])}),_c('vxe-table',{ref:"table",staticClass:"vxe-table-element",attrs:{"border":"","resizable":"","show-overflow":"","auto-resize":true,"header-row-style":_vm.headerStyle,"row-style":_vm.rowStyleFn,"align":"center","height":"600","data":_vm.records},on:{"checkbox-change":_vm.checkboxChange,"checkbox-all":_vm.checkboxChange}},[_c('vxe-table-column',{attrs:{"type":"checkbox","width":"60","fixed":"left"}}),_c('vxe-table-column',{attrs:{"type":"seq","title":"序号","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
                                var rowIndex = ref.rowIndex;
return [_vm._v(" "+_vm._s(rowIndex+1)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"车辆三检项"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_vm._v(" "+_vm._s(row.checkItemName)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"三检类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_vm._v(" "+_vm._s(row.typeStr)+" ")]}}])}),_c('vxe-table-column',{attrs:{"type":"seq","title":"排序"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_vm._v(" "+_vm._s(row.sort)+" ")]}}])}),_c('vxe-table-column',{attrs:{"field":"","title":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var row = ref.row;
return [_c('el-dropdown',{attrs:{"size":"small","type":"primary"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v(" 操作"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.$hasBtn('sys:permissions:config:timed_task:edit'))?_c('el-dropdown-item',{attrs:{"icon":"el-icon-edit"},nativeOn:{"click":function($event){_vm.recordRow=row;_vm.showAddOrEdit=true}}},[_vm._v("修改 ")]):_vm._e(),(_vm.$hasBtn('sys:permissions:config:timed_task:delete'))?_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除 ")]):_vm._e()],1)],1)]}}])})],1)],1)],1)],1),(_vm.showAddOrEdit)?_c('AddOrEdit',{attrs:{"visible":_vm.showAddOrEdit,"recordRow":_vm.recordRow},on:{"close":function (){
       _vm.showAddOrEdit=false;
       _vm.ajaxParam.currentPage = 1;
       _vm.queryList();
    },"update:visible":function($event){_vm.showAddOrEdit=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }