function key1Fn(rule, value, callback) {
    if (value.length > 20) {
        callback(new Error("字数不能多余于20个"));
    } else {
        callback();
    }
}

function sortFn(rule, value, callback) {
    const reg =/^[0-9]*$/;

    if ( !reg.test(value)) {
        callback(new Error("请输入数字"));
    } else {
        callback();
    }
}

export default {
    key: [
        { trigger: "change", required: true, message: "请录入数据" },
        { validator: key1Fn, trigger: "change" },
    ],
    companyIds:[
        { trigger: "change", required: true, message: "请选择所属公司" },
    ],
    type:[
        { trigger: "change", required: true, message: "请录入三检类型" },
    ],
    checkItemName:[
        { trigger: "change", required: true, message: "请输入检查项名称" },
    ],
    needImg:[
        { trigger: "change", required: true, message: "请选择是否需要相片" },
    ],
    sort:[
        { trigger: "change", required: true, message: "请输入排序" },
        { validator: sortFn, trigger: "change" },
    ],

    // key3: [{ validator: validatePass, trigger: "change" }],
    // key4: [{ validator: validatePass, trigger: "change" }],
};
