<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row" :info="dialog.info" @get-list="getList"
		 @refresh="shuaxin" />
		 <Look v-if="dialog.refresh1" :dialog.sync="dialog.show1" :row="dialog.row" :info="dialog.info" @get-list="getList"
		  @refresh="shuaxin" />
		<el-card style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button type="primary" size="mini" @click="openDialog({title:'自定义表单',addOr:'add'})">自定义表单</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="shuaxin">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="710" row-id="notice_id" :sort-config="{remote: true}"
				 :filter-config="{remote: true}" :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column field="formName" title="表单名称" show-overflow="title" />
					<vxe-table-column field="formTypeStr" title="类别" show-overflow="title" />
					<vxe-table-column field="companyName" title="所属公司" show-overflow="title" />
					<vxe-table-column field="remarks" title="备注" show-overflow="title" />
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-edit" @click.native="openDialog1({title:'查看',addOr:'look'},row)">查看</el-dropdown-item>
									<el-dropdown-item icon="el-icon-edit" @click.native="openDialog({title:'修改',addOr:'upd'},row)">修改</el-dropdown-item>
									<el-dropdown-item icon="el-icon-delete" @click.native="remove(row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]" :page-size="table.pageSize"
				 layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import Operate from './Operate'
	import Look from './Look'
	import {
		company,
	} from "@/api";
	export default {
		name: 'Index',
		components: {
			Look,
			Operate
		},
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 20,
					sortName: '',
					sortBy: ''
				},
				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					show: false,
					refresh1: false,
					show1: false,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			rowStyle,
			headerStyle,
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},
			openDialog1(info, row) {
				this.dialog.refresh1 = true
				this.dialog.show1 = true
				this.dialog.info = info
				this.dialog.row = row
			},
			getList() {
				this.loading = true;
				this.$axiosReq(
						'/data/server/escort/web/dynamicForm',
						null, {
							currentPage: this.table.currentPage,
							pageSize: this.table.pageSize,
						},
						"get"
					)
					.then((res) => {
						this.table.list = res.data.content;
						this.table.total = Number(res.data.totalElements);
					})
					.finally((res) => {
						this.loading = false;
					});
			},
			shuaxin() {
				this.dialog.refresh = false;
				this.dialog.refresh1 = false;
				this.dialog.show = false;
				this.dialog.show1 = false;
				this.table.currentPage = 1;
				this.table.list = [];
				this.getList();
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize;
				this.table.currentPage = 1;
				this.equipmentIds = [];
				this.table.list = [];
				this.getList();
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage;
				this.table.list = [];
				this.equipmentIds = [];
				this.getList();
			},
			remove(row) {
			  this.$confirm('确认删除吗！！！', '提示', {
			    confirmButtonText: '确定',
			    cancelButtonText: '取消',
			    type: 'warning'
			  }).then(() => {
			    this.$axiosReq('/data/server/escort/web/dynamicForm/' + row.id, null, null, 'delete').then(res => {
			      this.$message.success(res.msg)
			      this.getList()
			    })
			  }).catch(() => {
			    this.$message.info('取消了删除！！！')
			  })
			},
		}
	}
</script>

<style scoped>
	.rowBox {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	.searchInput {
		width: 260px;
	}
</style>
