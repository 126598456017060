<template>
    <div class="dashboard-container">
        <el-row :gutter="20">
            <el-card>
                <el-row type="flex">
                    <el-col :span="6">
                        <span class="text-primary text-pad-right"
                            >关键字搜索：</span
                        >
                        <el-input
                            v-model="ajaxParam.checkItemName"
                            size="mini"
                            class="input searchInput"
                            suffix-icon="el-icon-search"
                            placeholder="请输入车辆三检项"
                            maxlength="15"
                            clearable
                            @input="queryList"
                            style="width: 250px"
                        />
                    </el-col>
                    <el-col :span="6">
                        <div class="pl-1">
                            <span class="text-primary text-pad-right">公司：</span>
                        <GlobalCompanySelect
                            v-model="ajaxParam.companyId"
                            size="mini"
                            style="width: 250px"
                            @change="queryList"
                        />
                        </div>
                    </el-col>
                </el-row>
            </el-card>

            <el-card style="margin-top: 20px;">
                <el-row>
                    <vxe-toolbar>
                        <template v-slot:buttons>
                            <el-row type="flex" justify="between">
                                <el-col>
                                    <el-button v-if="$hasBtn('sys:permissions:config:timed_task:add')" type="primary" size="mini"
                                               @click="recordRow=null;showAddOrEdit=true">添加</el-button>
                                    <el-button  v-if="$hasBtn('sys:permissions:config:timed_task:batch:delete')" type="danger"
                                                size="mini" @click="batchDel" :disabled="batchRecordRows.length===0?true:false">批量删除</el-button>

                                </el-col>
                                <el-col :span="1">
                                    <vxe-button @click="()=>{
                                        ajaxParam.currentPage = 1;
                                        ajaxParam.pageSize = 20;
                                        ajaxParam.jobName = null;
                                        ajaxParam.jobStatus = null;
                                        queryList();
                                    }">刷新</vxe-button>
                                </el-col>
                            </el-row>
                        </template>
                    </vxe-toolbar>

                    <vxe-table
                            ref="table"
                            border
                            resizable
                            show-overflow
                            :auto-resize="true"
                            :header-row-style="headerStyle"
                            :row-style="rowStyleFn"
                            align="center"
                            class="vxe-table-element"
                            height="600"
                            :data="records"
                            @checkbox-change="checkboxChange"
                            @checkbox-all="checkboxChange"
                    >
                        <vxe-table-column type="checkbox" width="60" fixed="left" />

                        <vxe-table-column type="seq" title="序号" width="120" >
                            <template  v-slot="{ row ,rowIndex}">
                                {{rowIndex+1}}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="车辆三检项"  >
                            <template  v-slot="{ row }">
                                {{row.checkItemName}}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="三检类型"  >
                            <template  v-slot="{ row }">
                                {{row.typeStr}}
                            </template>
                        </vxe-table-column>

                        <vxe-table-column type="seq" title="排序"  >
                            <template  v-slot="{ row }">
                                {{row.sort}}
                            </template>
                        </vxe-table-column>




                        <vxe-table-column field="" title="操作" width="120">
                            <template v-slot="{ row }">
                                <el-dropdown size="small" type="primary">
                                    <el-button size="small" type="primary">
                                        操作<i class="el-icon-arrow-down el-icon--right" />
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-if="$hasBtn('sys:permissions:config:timed_task:edit')"
                                                          icon="el-icon-edit"
                                                          @click.native="recordRow=row;showAddOrEdit=true">修改
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="$hasBtn('sys:permissions:config:timed_task:delete')"
                                                          icon="el-icon-delete"
                                                          @click.native="del(row)">删除
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </vxe-table-column>
                    </vxe-table>

                </el-row>
            </el-card>
        </el-row>

        <AddOrEdit @close="()=>{
           showAddOrEdit=false;
           ajaxParam.currentPage = 1;
           queryList();
        }"  v-if="showAddOrEdit" :visible.sync="showAddOrEdit" :recordRow="recordRow"/>

    </div>
</template>

<script>
import AddOrEdit from "./components/AddOrEdit.vue";

export default {
    name: "timed_task",
    components:{AddOrEdit,},
    data(){
        return{
            ajaxParam:{
                // currentPage:1,
                // pageSize:20,
                companyId:null,       // 企业Id
                checkItemName:null,  // 检查项名称
                // direction:"ASC" //排序方式，默认降序 DESC, ASC
                // property:""   // 排序字段，默认创建时间
            },

            records:[],
            total:0,

            recordRow: null, //要操作的列表选中的一项  当前要操作的行
            batchRecordRows:[], //批量操作的数据

            showAddOrEdit:false,
            showLookDetails:false
        }
    },
    created() {
        this.queryList();
        },
    methods:{
       async queryList(){
           let {code,data} = await  this.$api.get("/car/server/escort/web/carInspectionItem",{
               params:this.ajaxParam
           });
           if(code === 200){

               this.records = data;
            //    this.total = ~~data.totalElements;
           }
       },

        checkboxChange(row) {
            //多选操作
            this.batchRecordRows = row.records;
        },

       async changeSwitch(row){
            let {code} = await  this.$api.post(!row.switch?`/quartz/server/escort/web/quartzManage/pause`:`/quartz/server/escort/web/quartzManage/resume`,null,{
                params:{
                    groupCode:row.groupCode,
                    jobCode:row.jobCode
                }
            });
            if(code === 200){
                this.$message({
                    message: `${row.switch?"启动":"停用"}成功`,
                    type: 'success',
                    center: true
                });
                this.ajaxParam.currentPage = 1;
                this.queryList();
            }
        },


        del(row){
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let {code} = await  this.$api.put(`/car/server/escort/web/carInspectionItem/ids/${row.id}`);
                if(code === 200){
                    this.$message({
                        message: '删除成功',
                        type: 'success',
                        center: true
                    });
                    this.ajaxParam.currentPage = 1;
                    this.queryList();
                }
            }).catch(() => {});
        },
        batchDel(){
        //    批量删除
            this.$confirm('确认删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let ids = this.batchRecordRows.map((item)=> item.id )
                let {code} = await  this.$api.put(`/car/server/escort/web/carInspectionItem/ids/${ids.toString()}`);
                if(code === 200){
                    this.$message({
                        message: '批量删除成功',
                        type: 'success',
                        center: true
                    });
                    this.ajaxParam.currentPage = 1;
                    this.batchRecordRows = [];
                    this.queryList();
                }
            }).catch(() => {});
        }
    }
}
</script>

<style scoped>
.dashboard-container{
   box-sizing: border-box;
    padding: 0 10px;
}
</style>
