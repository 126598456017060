<template>
    <el-dialog center
            v-bind="$attrs" v-on="$listeners"
            width="40%"
            :append-to-body="true"
            :destroy-on-close="true"
            :close-on-click-modal="false"
            :title="`${recordRow?'编辑':'添加'}`+'车辆三检项'"
    >
        <el-form
                v-loading="loading"
                ref="ruleForm"
                label-width="110px"
                class="allForm"
                :rules="rules"
                :model="ajaxParam"
        >
            <el-form-item label="所属公司"   v-if="$admin"
                prop="companyIds">
                <GlobalCompanySelect v-model="ajaxParam.companyIds" multiple
                        />
            </el-form-item>

            <el-form-item label="三检类型" prop="type">
               <GlobalDictSelect
                            parentCode="DRIVING_STATUS"
                            v-model="ajaxParam.type"
                           />
            </el-form-item>

            <el-form-item label="车辆三检项" prop="checkItemName">
                <el-input v-model="ajaxParam.checkItemName"    placeholder="请输入车辆三检项" maxlength="20" />
            </el-form-item>

            <el-form-item label="是否上传相片" prop="needImg">
               <GlobalDictSelect
                            parentCode="TRUE_FALSE"
                            v-model="ajaxParam.needImg"
                           />
            </el-form-item>
            <el-form-item label="排序" prop="sort">
                <el-input v-model="ajaxParam.sort"    placeholder="请输入车辆三检项" maxlength="10" />
            </el-form-item>
        </el-form>



        <div slot="footer" class="dialog-footer" style="text-align: center;">
            <el-button type="primary" @click="confirm">确定</el-button>
            <el-button type="info" @click="$emit('close')">取消</el-button>
        </div>


    </el-dialog>
</template>

<script>

import validates from "./validates.js";
import { Loading } from "element-ui";
export default {
    name:"AddOrEdit",

    computed: {
        rules() {
            return validates;
        },
    },
    props:{
        recordRow:{
            type: Object,
            default:()=>null
        }
    },
    data(){
        return{
            ajaxParam:{
                companyIds: this.$admin ? [] : this.$companyId, // 所属公司
                type: "" , //  三检类型(0行车前,1行车中,2行车后)
                checkItemName: "", // 检查项名称
                needImg: "" , // 是否检查照片
                sort:"", // 排序
            },
            loading:this.recordRow ? true: false,

            // 是否显示Cron表达式弹出层
            openCron: false,


        }
    },
    created() {
        this.recordRow&&this.lookfor();
    },
    methods:{
        async lookfor(){

            let {code,data} = await  this.$api.get(`/car/server//escort/web/carInspectionItem/${this.recordRow.id}`,{
                params:{

                }
            });
            if(code===200){
                Object.keys(this.ajaxParam).forEach(item=>{
                    this.ajaxParam[item] = data[item]
                })
                // 数字类型改为字符串类型
                this.ajaxParam.type = this.ajaxParam.type +"";
                this.ajaxParam.needImg = this.ajaxParam.needImg +"";
            }

            {


                let { code, data } = await this.$api.get(
                    "/oauth/server/escort/web/company?currentPage=1&pageSize=1000"
                );
                if (code == 200) {

                    let options = data.content;
                    let companyIds = this.ajaxParam.companyIds || [];
                    const obj = {};
                    options.map(item =>  obj[item.id] = true);

                    companyIds = companyIds.filter(item =>  obj[item])
                    this.ajaxParam.companyIds = companyIds;

                    setTimeout(()=>{ this.loading = false;},100)


                }
            }
        },

        confirm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.recordRow ? this.edit(): this.add()
                }
            });
        },

        async add(){
            let {code} = await  this.$api.post("/car/server/escort/web/carInspectionItem",
                this.ajaxParam
            );
            if(code === 200){
                this.$message({
                    message: '添加成功',
                    type: 'success',
                    center: true
                });
                this.$emit("close");
            }
        },

        async edit(){
            let {code} = await  this.$api.put(`/car/server/escort/web/carInspectionItem/${this.recordRow.id}`,
                this.ajaxParam);
            if(code === 200){
                this.$message({
                    message: '修改成功',
                    type: 'success',
                    center: true
                });
                this.$emit("close");
            }
        },

        /** 确定后回传值 */
        crontabFill(value) {
            this.ajaxParam.cronDesc = value
        },
    }
}
</script>

<style scoped lang="scss">
.allForm{
    .el-input{

    }
}
.el-form{
  padding-right: 25px;
  .el-select {
        width: 100%;
    }
    .el-date-editor {
        width: 100%;
    }
}
.el-button-cron{
  transform: translateX(5px);
}


</style>
