<template>
	<el-dialog :visible.sync="show" width="80%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row>
				<el-col :span="2">
					<el-button type="primary" @click="close">返回</el-button>
				</el-col>
				<el-col :span="22">
					<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<div style="width: 100%;height: 20px;"></div>
		<el-row>
			<el-col :span="3">
				<div style="font-size: 18px;font-weight: bold;border-left: 5px solid #00A0E9;padding-left: 5px;">表单信息</div>
			</el-col>
			<el-col :span="20">
				<el-form ref="ruleForm" :model="form" label-width="110px" class="allForm">
					<el-form-item label="表名" required>
						<el-input v-model="form.formName" class="formInput" :disabled="true" />
					</el-form-item>
					<el-form-item label="类别">
						<el-input v-model="form.formTypeStr" class="formInput" :disabled="true" />
					</el-form-item>
					<el-form-item label="公司">
						<el-input v-model="form.companyName" class="formInput" :disabled="true" />
					</el-form-item>
					<el-form-item label="备注">
						<el-input v-model="form.remarks" class="formInput" :disabled="true" />
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div style="font-size: 18px;font-weight: bold;border-left: 5px solid #00A0E9;padding-left: 5px;">字段信息</div>
		<div class="boxW">
			<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
			 align="center" class="vxe-table-element" :data="table.list">
				<vxe-table-column type="seq" width="40" />
				<vxe-table-column title="列名" field="fieldName" show-overflow="title"></vxe-table-column>
				<vxe-table-column title="属性名" field="fieldValue" show-overflow="title"></vxe-table-column>
				<vxe-table-column title="输入方式" field="inputTypeStr" show-overflow="title"></vxe-table-column>
				<!-- <vxe-table-column title="列名" field="fieldName" show-overflow="title"></vxe-table-column> -->
				<vxe-table-column title="选择项（多个选项以英文逗号隔开）" field="options" show-overflow="title" width="280"></vxe-table-column>
				<vxe-table-column title="默认值" field="defaultValue" show-overflow="title"></vxe-table-column>
				<vxe-table-column title="是否必填" field="isRequiredStr" show-overflow="title"></vxe-table-column>
				<vxe-table-column title="排序" field="sort" show-overflow="title"></vxe-table-column>

                <vxe-table-column title="输入最大长度"  field="maxLength">

                </vxe-table-column>
            </vxe-table>
		</div>
		<!-- <div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div> -->
	</el-dialog>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: true,
				loading: false,
				form: {
					formName: '',
					formTypeStr: '',
					companyName: '',
					remarks: '',
				},
				company: [],
				table: {
					list: [],
				},
				codeList: [],
				inputTypeList: []
			}
		},
		created() {},
		mounted() {
			this.getUpd();
		},
		methods: {
			rowStyle,
			headerStyle,
			// 关闭模态框
			close() {
				this.$emit('refresh')
				this.show = false
			},
			getUpd() {
				this.$axiosReq(
					'/data/server/escort/web/dynamicForm/' + this.row.id,
					null, null,
					"get"
				).then((res) => {
					this.form.companyName = res.data.companyName;
					this.form.formName = res.data.formName;
					this.form.formTypeStr = res.data.formTypeStr;
					this.form.remarks = res.data.remarks;
					if (res.data.dynamicFormFieldViewVos && res.data.dynamicFormFieldViewVos.length > 0) {
						res.data.dynamicFormFieldViewVos.forEach(function(item) {
							item.select = 1;
						});
						this.table.list = res.data.dynamicFormFieldViewVos;
					}
				});
			},
		}
	}
</script>

<style scoped>
	.boxW {
		width: 98%;
		/* border: 1px solid #999; */
		padding: 10px 10px 5px;
		box-sizing: border-box;
		border-radius: 10px;
		margin-top: 15px;
	}
</style>
