<template>
	<el-dialog :visible.sync="show" width="80%" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false"
	 @close="close">
		<div slot="title" class="header-title">
			<el-row>
				<el-col :span="24">
					<span class="title-name" style="font-size: 20px;">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<div style="width: 100%;height: 20px;"></div>
		<el-row>
			<el-col :span="3">
				<div style="font-size: 18px;font-weight: bold;border-left: 5px solid #00A0E9;padding-left: 5px;">表单信息</div>
			</el-col>
			<el-col :span="20">
				<el-form ref="ruleForm" :model="form" label-width="110px" class="allForm">
					<el-form-item label="表名" required>
						<el-input size="mini"  v-model="form.formName" class="formInput" />
					</el-form-item>
					<el-form-item label="类别">
						<el-select v-model="form.formType" class="formInput w-100">
							<el-option v-for="item in codeList" :key="item.dictCode" :label="item.dictName" :value="item.dictCode"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="公司">
						<el-select v-model="form.companyId" class="formInput w-100" filterable clearable>
							<el-option v-for="(v, i) in company" :key="i" :value="v.id" :label="v.companyName" />
						</el-select>
					</el-form-item>
					<el-form-item label="备注">
						<el-input size="mini"  v-model="form.remarks" class="formInput" />
					</el-form-item>
				</el-form>
			</el-col>
		</el-row>
		<div style="font-size: 18px;font-weight: bold;border-left: 5px solid #00A0E9;padding-left: 5px;">字段信息</div>
		<div class="boxW">
			<vxe-toolbar>
				<template v-slot:buttons>
					<el-row type="flex">
						<el-col>
							<el-button type="primary" size="mini" @click="addCurriculumVitaeSo">+添加</el-button>
						</el-col>
					</el-row>
				</template>
			</vxe-toolbar>
			<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
			 align="center" class="vxe-table-element" :data="table.list">
				<vxe-table-column type="seq" width="40" />
				<vxe-table-column title="列名">
					<template v-slot="{ row }">
						<el-input size="mini"  v-model="row.fieldName" class="formInput" type="text" placeholder="请输入列名" />
					</template>
				</vxe-table-column>
				<vxe-table-column title="属性名">
					<template v-slot="{ row }">
						<el-input size="mini"  v-model="row.fieldValue" class="formInput" type="text" placeholder="请输入属性名" />
					</template>
				</vxe-table-column>
				<vxe-table-column title="输入方式">
					<template v-slot="{ row }">
						<el-select size="mini" v-model="row.inputType" class="formInput w-100">
							<el-option v-for="item in inputTypeList" :key="item.id" :label="item.dictName" :value="item.dictCode"></el-option>
						</el-select>
					</template>
				</vxe-table-column>
				<vxe-table-column width="300" title="选择项（多个选项以英文逗号隔开）">
					<template v-slot="{ row }">
						<el-input size="mini"  v-model="row.options" class="formInput" type="text" placeholder="请输入选择项" />
					</template>
				</vxe-table-column>
				<vxe-table-column title="默认值">
					<template v-slot="{ row }">
						<el-input size="mini"  v-model="row.defaultValue" class="formInput" type="text" placeholder="请输入默认值" />
					</template>
				</vxe-table-column>
				<vxe-table-column title="是否必填">
					<template v-slot="{ row }">
						<el-select size="mini" v-model="row.isRequired" class="formInput w-100">
							<el-option v-for="item in isRequiredList" :key="item.id" :label="item.dictName" :value="item.dictCode"></el-option>
						</el-select>
					</template>
				</vxe-table-column>
				<vxe-table-column title="排序" >
					<template v-slot="{ row }">
						<el-input size="mini"  v-model="row.sort" class="formInput" type="text" placeholder="请输入排序" />
					</template>
				</vxe-table-column>

                <vxe-table-column title="输入最大长度" >
                    <template v-slot="{ row }">
                        <el-input size="mini"   placeholder="字符数大于3"
                                   :rules="[
      { min: 3, message: '字符数大于3', trigger: 'blur' }
    ]"
                                   v-model="row.maxLength" class="formInput" type="text"  />
                    </template>
                </vxe-table-column>

				<vxe-table-column width="80"  title="操作">
					<template v-slot="{ row }">
						<el-button v-if="row.select == 2" type="danger" size="mini" @click="deleteOne(row.num)">删除</el-button>
					</template>
				</vxe-table-column>
			</vxe-table>
		</div>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		company,
	} from "@/api";
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				show: true,
				loading: false,
				form: {
					formName: '',
					formType: '',
					companyId: '',
					remarks: '',
				},
				company: [],
				table: {
					list: [],
				},
				codeList: [],
				inputTypeList: [],
				isRequiredList: []
			}
		},
		created() {
			this.getParentCode();
			this.getParentCode1();
			this.getParentCode2();
			this.getCompany();
		},
		mounted() {
			if (this.info.addOr == 'upd') {
				this.getUpd();
			}
		},
		methods: {
			rowStyle,
			headerStyle,
			// 关闭模态框
			close() {
				this.$emit('refresh')
				this.show = false
			},
			getParentCode() {
				this.loading = true;
				this.$axiosReq('/data/server/escort/web/dict/parentCode/DYNAMIC_FORM_CATEGORY', null, null, "get")
					.then((res) => {
						if (res.data && res.data.length > 0) {
							this.codeList = res.data;
						}
					});
			},
			getParentCode1() {
				this.loading = true;
				this.$axiosReq('/data/server/escort/web/dict/parentCode/INPUT_TYPE', null, null, "get")
					.then((res) => {
						if (res.data && res.data.length > 0) {
							this.inputTypeList = res.data;
						}
					});
			},
			getParentCode2() {
				this.loading = true;
				this.$axiosReq('/data/server/escort/web/dict/parentCode/TRUE_FALSE', null, null, "get")
					.then((res) => {
						if (res.data && res.data.length > 0) {
							this.isRequiredList = res.data;
						}
					});
			},
			getCompany() {
				this.$axiosReq(
					company,
					null, {
						pageSize: 10000,
					},
					"get"
				).then((res) => {
					this.company = res.data.content;
				});
			},
			commit() {
				if (!this.form.formName) {
					this.$message.error("请输入表名");
					return false;
				}
				if (this.info.addOr == 'upd') {
					this.$axiosReq(
						'/data/server/escort/web/dynamicForm/' + this.row.id, {
							"companyId": this.form.companyId,
							"dynamicFormFieldDtos": this.table.list,
							"formName": this.form.formName,
							"formType": this.form.formType,
							"remarks": this.form.remarks
						}, null,
						"put"
					).then((res) => {
						this.close();
					});
				} else {
					this.$axiosReq(
						'/data/server/escort/web/dynamicForm', {
							"companyId": this.form.companyId,
							"dynamicFormFieldDtos": this.table.list,
							"formName": this.form.formName,
							"formType": this.form.formType,
							"remarks": this.form.remarks
						}, null,
						"post"
					).then((res) => {
						this.close();
					});
				}
			},
			getUpd() {
				this.$axiosReq(
					'/data/server/escort/web/dynamicForm/' + this.row.id,
					null, null,
					"get"
				).then((res) => {
					this.form.companyId = res.data.companyId;
					this.form.formName = res.data.formName;
					this.form.formType = res.data.formType;
					this.form.remarks = res.data.remarks;
					if (res.data.dynamicFormFieldViewVos && res.data.dynamicFormFieldViewVos.length > 0) {
						res.data.dynamicFormFieldViewVos.forEach(function(item) {
							item.select = 1;
							item.isRequired = item.isRequired + '';
						});
						this.table.list = res.data.dynamicFormFieldViewVos;
					}
				});
			},
			addCurriculumVitaeSo() {
				var num = this.table.list.length;
				var sortNum = num * 10;
				this.table.list.push({
					"defaultValue": '',
					"fieldName": '',
					"fieldValue": '',
					"inputType": '',
					"sort": sortNum,
					"options": '',
					"num": num,
					"select": 2
				})
				this.$refs.table.reloadData(this.table.list)
			},
			deleteOne(num) {
				var arr = [];
				this.table.list.forEach(function(item) {
					if (item.num !== num) {
						arr.push(item);
					}
				});
				this.table.list = arr;
			},
		}
	}
</script>

<style scoped>
	.boxW {
		width: 98%;
		/* border: 1px solid #999; */
		padding: 10px 10px 5px;
		box-sizing: border-box;
		border-radius: 10px;
		margin-top: 15px;
	}
</style>
